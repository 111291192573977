module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-34970259-28","head":false,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Portfolio Ruben Klein","short_name":"Portfolio","start_url":"/","theme_color":"#04aa60","background_color":"#27293e","display":"minimal-ui","orientation":"any","cache_busting_mode":"query","icons":[{"src":"/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicon/icon-bg-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicon/icon-bg-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicon/icon-bg-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/icon-bg-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicon/icon-bg-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicon/icon-bg-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
